// settings
@import "settings/mixins";
@import "settings/colors";
@import "settings/typography";
@import "settings/variables";

.cpbulba-footer {
    background: $default-color;
    padding: 1rem;
    clear: both;
    & *{
        box-sizing: border-box;
    }
    @include media("tablet-wide", min) {
        display: flex;
        align-items: center;
    }
    @include media("tablet-wide", max) {
        text-align: center;
    }
    a {
        color: #fff;
        &:hover,
        &:focus {
            color: $default-color2;
        }
    }
    .logo {
        font-size: 2.5em;
        display: inline-flex;
        span {
            display: none;
        }
    }
    nav {
        flex: 1;
        @include media("tablet-wide", min) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 2rem;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            @include media("tablet-wide", min) {
                display: flex;
                align-items: center;
            }
            li {
                &:not(:last-child) {
                    @include media("tablet-wide", min) {
                        margin-right: 2rem;
                    }
                }
            }
            &.menu {
                font-family: $heading-font-family;
                font-weight: $heading-font-weight;
                text-transform: uppercase;
                font-size: 0.75em;
                @include media("tablet-wide", max) {
                    margin: 1rem 0;
                }
                li {
                    &:not(:last-child) {
                        @include media("tablet-wide", max) {
                            margin-bottom: .5rem;
                        }
                    }
                }
            }
            &.social {
                font-size: 2em;
                @include media("tablet-wide", max) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                li {
                    &:not(:last-child) {
                        @include media("tablet-wide", max) {
                            margin-right: 2rem;
                        }
                    }
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    @media print{
        display: none;
    }
}